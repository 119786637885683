<template>
  <a href="https://wa.me/+6580426428" target="_blank" class="whatsapp-button">
    <img src="\assets\whatsapp.svg" alt="Whatsapp Logo" width="40" height="40" />
  </a>
</template>

<script>
export default {
  name: 'WhatsappButton'
}
</script>

<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #25D366;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 50;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}
</style>